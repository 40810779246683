export const COLUMNS = [
    {
        Header: 'Class ID',
        accessor: 'cid',
        Footer: "Class ID"
    },
    {
        Header: 'Class Name',
        accessor: 'className',
        Footer: 'Class Name',
    },
    {
        Header: 'Semester',
        accessor: 'semester',
        Footer: 'Semester'
    },
    {
        Header: 'Year',
        accessor: 'yearNumber',
        Footer: "Year"

    },

]